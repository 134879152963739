import { h, Component } from "preact";
import { Router } from "preact-router";

// Code-splitting is automated for routes
import Chapters from "./routes/Chapters";

// Jahresgespräche
import Jahresgespraech2021 from "async!./routes/Chapters/jahresgespraech_2021";
import Jahresgespraech2022 from "async!./routes/Chapters/jahresgespraech_2022";
import Jahresgespraech2023 from "async!./routes/Chapters/jahresgespraech_2023";
import Jahresgespraech2024 from "async!./routes/Chapters/jahresgespraech_2024";
import Jahresgespraech2025 from "async!./routes/Chapters/jahresgespraech_2025";

// Kapitel
import MissionToZero from "async!./routes/Chapters/mission-to-zero";
import BuschFreeAtHome from "async!./routes/Chapters/busch-free-at-home";
import WelcomeIP from "async!./routes/Chapters/welcome-ip";
import Welcome from "async!./routes/Chapters/welcome";
import Knx from "async!./routes/Chapters/knx";
import ComfortLine from "async!./routes/Chapters/comfortline";
import Installationsgeraete from "async!./routes/Chapters/installationsgeraete";
import BeruehrungslosesSchalten from "async!./routes/Chapters/beruehrungsloses-schalten";
import Cwa from "async!./routes/Chapters/cwa";
import Architekten from "async!./routes/Chapters/architekten";
import ServiceAndTools from "async!./routes/Chapters/serviceandtools";
import BuschFlex from "async!./routes/Chapters/busch-flex";
import UK600AK600 from "async!./routes/Chapters/UK600AK600";
import SmarterGallery from "async!./routes/Chapters/smarter-gallery";
import ABBSmarterMobility from "async!./routes/Chapters/abb-smarter-mobility";
import Energiemonitoring from "async!./routes/Chapters/Energiemonitoring";
import Cylon from "async!./routes/Chapters/cylon";
import LbInnovations from "async!./routes/Chapters/lbInnovations";
import ModerneElektroinstallation from "async!./routes/Chapters/moderneElektroinstallation";
import USV from "async!./routes/Chapters/USV";
import News from "async!./routes/Chapters/News";
import FlexLine from "async!./routes/Chapters/flexline";

//Redux
import configureStore from "./redux/store/configureStore";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

//Material UI
import { SvgIcon, ThemeProvider } from "@material-ui/core";
import { theme } from "./theme";
import ModalProvider from "mui-modal-provider";

//Material UI Picker
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import deLocale from "date-fns/locale/de";

//StylesProvider for overriding MaterialUI
import {
  StylesProvider,
  createGenerateClassName,
} from "@material-ui/core/styles";

import CustomerView from "./routes/CustomerView";

//Toastify
import { ToastContainer, toast, Slide as SlideAnimation } from "react-toastify";

//tippy.js
import "tippy.js/dist/tippy.css";

//Sentry https://docs.sentry.io/platforms/javascript/react/
// import * as Sentry from '@sentry/react';

//Fullpage ScrollOverflow
import "fullpage.js/vendors/scrolloverflow";

import "aos/dist/aos.css";

import root from "window-or-global";
import PrivateRoute from "./components/PrivateRoute";

import APIService from "./api/index";
import AppWrap from "./appWrap";
import Neuheiten2024 from "./routes/Chapters/neuheiten-2024";

const generateClassName = createGenerateClassName({
  productionPrefix: "qmarketing",
});

export default class App extends Component {
  /** Gets fired when the route changes.
   *	@param {Object} event		"change" event from [preact-router](http://git.io/preact-router)
   *	@param {string} event.url	The newly routed URL
   */
  handleRoute = (e) => {
    this.currentUrl = e.url;
  };

  handleConnection(event) {
    if (event.type === "offline") {
      toast.error("Keine Internetverbindung.");
    }
    if (event.type === "online") {
      toast.info("Sie sind wieder online.");
    }
  }

  async componentDidMount() {
    if (process.env.NODE_ENV !== "development") {
      await APIService.get("api/pb/hit");
    }

    root.addEventListener("online", this.handleConnection);
    root.addEventListener("offline", this.handleConnection);
  }

  render() {
    const { store, persistor } = configureStore({});

    const CloseButton = ({ closeToast }) => (
      <SvgIcon onClick={closeToast}>
        <svg
          clip-rule="evenodd"
          fill-rule="evenodd"
          stroke-linejoin="round"
          stroke-miterlimit="2"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="m12 10.93 5.719-5.72c.146-.146.339-.219.531-.219.404 0 .75.324.75.749 0 .193-.073.385-.219.532l-5.72 5.719 5.719 5.719c.147.147.22.339.22.531 0 .427-.349.75-.75.75-.192 0-.385-.073-.531-.219l-5.719-5.719-5.719 5.719c-.146.146-.339.219-.531.219-.401 0-.75-.323-.75-.75 0-.192.073-.384.22-.531l5.719-5.719-5.72-5.719c-.146-.147-.219-.339-.219-.532 0-.425.346-.749.75-.749.192 0 .385.073.531.219z" />
        </svg>
      </SvgIcon>
    );

    return (
      <div id="app">
        <ToastContainer
          position="bottom-center"
          toastClassName="toast-container"
          autoClose={2500}
          transition={SlideAnimation}
          hideProgressBar
          draggablePercent={40}
          closeButton={CloseButton}
          icon={false}
          limit={3}
          newestOnTop={true}
        />
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <StylesProvider
              injectFirst={false}
              generateClassName={generateClassName}
            >
              <ThemeProvider theme={theme}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
                  <ModalProvider>
                    <AppWrap>
                      <Router onChange={this.handleRoute}>
                        <CustomerView path="/c" />
                        <Chapters path="/" />
                        <MissionToZero path="/mission-to-zero" />
                        <BuschFreeAtHome path="/free-at-home" />
                        <Welcome path="/welcome" />
                        <WelcomeIP path="/welcome-ip" />
                        <Knx path="/knx" />
                        <ComfortLine path="/comfort-line" />
                        <Installationsgeraete path="/installationsgeraete" />
                        <BeruehrungslosesSchalten path="/beruehrungsloses-schalten" />
                        <Cwa path="/cwa" />
                        <Architekten path="/architekten" />
                        <ServiceAndTools path="/serviceandtools" />
                        <UK600AK600 path="/uk600-ak600" />
                        <PrivateRoute
                          path="/jahresgespraech-2021"
                          component={Jahresgespraech2021}
                        />
                        <PrivateRoute
                          path="/jahresgespraech-2022"
                          component={Jahresgespraech2022}
                        />
                        <BuschFlex path="/busch-flex" />
                        <Cylon path="/cylon" />
                        <SmarterGallery path="/smarter-gallery" />
                        <ABBSmarterMobility path="/abb-smarter-mobility" />
                        <LbInnovations path="/innovations-2022" />
                        <Energiemonitoring path="/energiemonitoring" />
                        <PrivateRoute
                          path="/jahresgespraech-2023"
                          component={Jahresgespraech2023}
                        />
                        <ModerneElektroinstallation path="/moderne-elektroinstallation" />
                        <USV path="/usv" />
                        <News path="/news-und-aktionen" />
                        <PrivateRoute
                          path="/jahresgespraech-2024"
                          component={Jahresgespraech2024}
                        />
                        <FlexLine path="/flexline" />
                        <Neuheiten2024 path="/neuheiten-2024" />
                        <PrivateRoute
                          path="/jahresgespraech-2025"
                          component={Jahresgespraech2025}
                        />
                      </Router>
                    </AppWrap>
                  </ModalProvider>
                </MuiPickersUtilsProvider>
              </ThemeProvider>
            </StylesProvider>
          </PersistGate>
        </Provider>
      </div>
    );
  }
}
