import { h } from "preact";
import { TextareaAutosize, makeStyles } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles(() => ({
  textAreaStyling: {
    minWidth: "100%",
    border: "none",
    fontFamily: "Averta-Regular",
    fontSize: "18px",
  },
}));

export default function QTextFieldAction({
  value,
  valueID,
  setSetupField,
  className,
  rowsMin,
  rowsMax,
  placeholder,
  disabled,
}) {
  const classes = useStyles();

  const handleFieldChange = (e) => {
    setSetupField({
      id: valueID,
      value: e.currentTarget.value,
    });
  };

  return (
    <TextareaAutosize
      value={value}
      id={valueID}
      className={clsx(classes.textAreaStyling, ...className)}
      onChange={handleFieldChange}
      placeholder={placeholder}
      minRows={rowsMin}
      maxRows={rowsMax}
      aria-label="notizen"
      disabled={disabled}
    />
  );
}

QTextFieldAction.defaultProps = {
  className: "",
  rowsMin: 3,
  placeholder: "Ihre Notizen ...",
  disabled: false,
};
