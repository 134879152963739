const SET_CATEGORIES = "playbook/categories/SET_CATEGORIES";

const initialState = {
  categories: {
    welcome: true,
    freeathome: true,
    missiontozero: true,
    welcomeip: true,
    knx: true,
    comfortline: true,
    installationsgeraete: true,
    beruehrungslosesschalten: true,
    cwa: true,
    jahresgespraech_2021: true,
    branchenkonfiguratorIndustrie: true,
    architekten: true,
    jahresgespraech_2022: true,
    serviceandtools: true,
    buschflex: true,
    uk600ak600: true,
    cylon: true,
    smarterGallery: true,
    abbSmarterMobility: true,
    lbInnovations: true,
    energiemonitoring: true,
    jahresgespraech_2023: true,
    jahresgespraech_2024: true,
    moderneElektroinstallation: true,
    usv: true,
    newsUndAktionen: true,
    flexline: true,
    neuheiten2024: true,
    jahresgespraech_2025: true,
  },
  fetchDate: null,
};

const categories = (state = initialState, action) => {
  switch (action.type) {
    case SET_CATEGORIES: {
      return {
        ...state,
        categories: action.categories,
        fetchDate: new Date(),
      };
    }
    default:
      return state;
  }
};

//actions
export const setCategories = (categories) => ({
  type: SET_CATEGORIES,
  categories,
});

//selector
export const getCategoriesData = (state) => state.categories;

export default categories;
